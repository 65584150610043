import { Avatar, Badge, Box, Chip, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react"
import dayjs from "dayjs"

import {
    // MRT_Table,
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';
import { uniqueId } from "lodash";
import { IconEye } from "@tabler/icons";
import { palette } from "src/theme/style/palette";


interface ITeamProgramsTable {
    partners: any[]
    updPersonInfoId: (id: number, refererId: number) => void
}

export const TeamProgramsTable: FC<ITeamProgramsTable> = ({ updPersonInfoId, partners }) => {
    const [tableData, setTableData] = useState<any[]>([]);
    const updPersonInfoIdLocal = (id: number, refererId: number) => {
        updPersonInfoId(id, refererId);
    }

    console.log(partners);

    // ФОРМИРОВАНИЕ СТРОК
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [

            {
                accessorFn: (row) => <Typography variant="h6" sx={{ mr: 1.5 }}>
                    {row.number}
                </Typography>,
                header: '№',
                size: 10,
            },

            {
                accessorFn: (row) => <Box display='flex' alignItems='center'>
                    <Box sx={{ cursor: "pointer" }} onClick={() => updPersonInfoIdLocal(row.data.id, row.data.referer_id)} display='flex' alignItems='center'>
                        <IconEye style={{ color: palette.blue }} />
                        <Avatar
                            sx={{ ml: 1 }}
                            alt={`${row.data?.first_name} ${row.data?.last_name}`}
                            src={row.data?.avatar || ''}
                        />
                    </Box>
                    <Typography variant="h6" sx={{ fontWeight: 600, ml: 1.5 }}>
                        {row.data?.login}
                    </Typography>
                </Box>,
                header: 'Логин',
                size: 20,
            },

            // Активные уровни
            // Уровни партнёров

            {
                accessorFn: (row) => <Box>

                    {/* {JSON.stringify(row)} */}
                    {row.data?.programs?.map((el: any) => {
                        // console.log(el.name);

                        return (<Box key={el.name} display='inline-flex'>
                            {/* <Chip label={el.name} sx={{ m: 1, backgroundColor: el.background, color: el.color }} /> */}
                            {el?.list?.map((item: any, i: number) => {
                                const name = i == 0 ? el.name + " " + item.name : item.name;


                                return <Badge key={uniqueId()} badgeContent={item.clones} color="error" sx={{ m: .9 }}>
                                    <Chip label={name} sx={{ backgroundColor: el.background, color: el.color, "&>.MuiChip-label": { py: 0.2, px: .9 } }} />
                                </Badge>
                            })}
                        </Box>)
                    })}
                </Box>,
                header: 'Активные уровни',
                size: 500,
            },

            // {
            //     accessorFn: (row) => <Box>
            //         <Typography variant="h6" sx={{ fontWeight: 600 }}>
            //             {dayjs(row.data.created_at).format('DD.MM.YYYY')}
            //         </Typography>
            //         <Typography variant="h6" sx={{ fontWeight: 600 }}>
            //             {dayjs(row.data.created_at).format('HH:mm:ss')}
            //         </Typography>
            //     </Box>,
            //     header: 'Дата регистрации',
            //     size: 30,
            // },
            // {
            //     accessorFn: (row) => <Box>
            //         <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: '8px' }}>
            //             {row.data.email}
            //         </Typography>

            //     </Box>,
            //     header: 'Контакты',
            //     size: 40,
            // },
            // {
            //     accessorFn: (row) => <Box>
            //         <Typography sx={{ fontSize: "16px" }}>Задолженность NULL: <b>{new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.abonent_block)}</b></Typography>
            //         <Typography sx={{ fontSize: "16px" }}>Задолженность SPACE: <b>{new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.abonent_block2)}</b></Typography>
            //     </Box>,
            //     header: 'Задолженность',
            // },

            // {
            //     accessorFn: (row) => <Typography variant="h6" sx={{ fontWeight: 600 }}>
            //         {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.balance_zho)}
            //     </Typography>,
            //     header: 'Баланс Space',
            //     size: 15,
            // },
            // {
            //     accessorFn: (row) => <Typography variant="h6" sx={{ fontWeight: 600 }}>
            //         {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.balance_null)}
            //     </Typography>,
            //     header: 'Баланс NULL',
            //     size: 15,
            // },
            // {
            //     accessorFn: (row) => <Typography variant="h6" sx={{ fontWeight: 600 }}>
            //         {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.balance)}
            //     </Typography>,
            //     header: 'Баланс',
            //     size: 15,
            // },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: tableData,
        state: {
            // columnFilters,
            // globalFilter,
            // isLoading,
            // pagination,
            // showAlertBanner: isError,
            // showProgressBars: isRefetching,
            // sorting,
        },
        enableStickyHeader: false,
        enableStickyFooter: false,
        muiTablePaperProps: {
            elevation: 0, //change the mui box shadow
            sx: {
                borderRadius: '0',
            },
        },
        // enableRowSelection: true, // чекбоксы для выбора
        enableGlobalFilter: false, // поиск по все таблице в шапке
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,

        enableColumnFilters: false,
        enableColumnActions: false, // настройки колонки
        enableSorting: false, // отключает сортировку по колонкам
        paginationDisplayMode: 'pages',

        muiTableBodyCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
            },
        },
        muiTableProps: {
            sx: { boxShadow: "none" }
        },
        muiTableContainerProps: {
            sx: { maxHeight: '100%', boxShadow: "none" }
        },
        muiTableHeadCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
                fontSize: "1rem"
            },
        },
        muiPaginationProps: {
            rowsPerPageOptions: [10, 30, 50, 100],
            showRowsPerPage: false,
            showFirstButton: false,
            showLastButton: false,
            shape: 'rounded',
            // showRowsPerPage: false, // показать/скрыть колич на странице
            variant: 'outlined',
        },
        localization: {
            and: 'и',
            cancel: 'Отмена',
            search: 'Искать',
            showHideSearch: 'Поиск по всей таблице',
            rowsPerPage: 'Cтрок',
            goToLastPage: 'Предыдущая',
            goToNextPage: 'Следующая',
        },
        renderEmptyRowsFallback: () => (
            <Typography textAlign='center'>Ничего не найдено</Typography>
        ),

    });

    const initDataTable = (anyData: any[]) => {

        const arrDataTable: any = [];
        anyData.forEach((e, i) => {
            arrDataTable.push({
                number: i + 1,
                data: e
            })
        });
        setTableData(arrDataTable);
    }

    useEffect(() => {
        if (partners) initDataTable(partners);
    }, [partners])

    return (
        <>
            <MaterialReactTable table={table} />

        </>
    )
}
