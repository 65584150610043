import React, { useEffect } from 'react';
import {
  Avatar,
  Box,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Button,
  Divider,
  Stack
} from '@mui/material';

import { SCR } from './SCR';

const TelegramProfileTab = () => {

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <SCR />
        </Grid>
      </Grid>

    </>
  );
};

export default TelegramProfileTab;
