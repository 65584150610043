import { Avatar, Box, Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import dayjs from "dayjs"
// import relativeTime from "dayjs/plugin/relativeTime"
import { FC, useEffect, useMemo, useState } from "react"
// import { TablePartners } from "./TablePartners"
import { useSelector } from "react-redux"
import { AppState } from "src/store/Store"
import {
    // MRT_Table,
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';

import tg from "src/assets/images/socials/tg.png";
import vk from "src/assets/images/socials/vk.png";
import wa from "src/assets/images/socials/wa.png";


interface IPartnersContent {
    data: { info: any, sponsors: any, teams: any, count: number, referer: any }
    updPersonInfoId: (id: number) => void
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export const PartnersContent: FC<IPartnersContent> = ({ data, updPersonInfoId }) => {
    const [tableData, setTableData] = useState<any[]>([]);
    const updPersonInfoIdLocal = (id: number) => updPersonInfoId(id);

    const { data: user } = useSelector((state: AppState) => state.app);
    const dateRegUser = dayjs(data.info.created_at).format('DD.MM.YYYY');


    // MATERIAL TABLE

    // ФОРМИРОВАНИЕ СТРОК
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [

            {
                accessorFn: (row) => <Typography variant="h6" sx={{ mr: 1.5 }}>
                    {row.number}
                </Typography>,
                header: '№',
                size: 10,
            },

            {
                accessorFn: (row) => <Box onClick={() => updPersonInfoIdLocal(row.data.id)} sx={{ cursor: "pointer" }} display='flex' alignItems='center'>
                    {row.data.avatar && <Avatar
                        alt={`${row.data.first_name} ${row.data.last_name}`}
                        src={row.data.avatar}
                    />}

                    {!row.data.avatar && <Avatar  {...stringAvatar(`${row.data.first_name} ${row.data.last_name}`)} />}
                    <Box sx={{ ml: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {row.data.login}
                        </Typography>
                    </Box>
                </Box>,
                header: 'Логин',
                size: 20,
            },


            {
                accessorFn: (row) => <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {dayjs(row.data.created_at).format('DD.MM.YYYY')}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {dayjs(row.data.created_at).format('HH:mm:ss')}
                    </Typography>
                </Box>,
                header: 'Дата регистрации',
                size: 30,
            },
            {
                accessorFn: (row) => <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: '8px' }}>
                        {row.data.email}
                    </Typography>
                    <Box display='flex' justifyContent='start'>
                        {row.data.vk && <a href={row.data.vk} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                            <Avatar src={vk} sx={{ width: 24, height: 24 }} />
                        </a>}

                        {row.data.telegram && <a href={row.data.telegram} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                            <Avatar src={tg} sx={{ width: 24, height: 24 }} />
                        </a>}

                        {row.data.whatsapp && <a href={row.data.whatsapp} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                            <Avatar src={wa} sx={{ width: 24, height: 24 }} />
                        </a>}
                    </Box>
                </Box>,
                header: 'Контакты',
                size: 40,
            },
            {
                accessorFn: (row) => <Box>
                    <Typography sx={{ fontSize: "16px" }}>Задолженность NULL: <b>{new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.abonent_block)}</b></Typography>
                    <Typography sx={{ fontSize: "16px" }}>Задолженность SPACE: <b>{new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.abonent_block2)}</b></Typography>
                </Box>,
                header: 'Задолженность',
            },

            {
                accessorFn: (row) => <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.balance_zho)}
                </Typography>,
                header: 'Баланс Space',
                size: 15,
            },
            {
                accessorFn: (row) => <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.balance_null)}
                </Typography>,
                header: 'Баланс NULL',
                size: 15,
            },
            {
                accessorFn: (row) => <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.data.balance)}
                </Typography>,
                header: 'Баланс',
                size: 15,
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: tableData,
        state: {
            // columnFilters,
            // globalFilter,
            // isLoading,
            // pagination,
            // showAlertBanner: isError,
            // showProgressBars: isRefetching,
            // sorting,
        },
        enableStickyHeader: false,
        enableStickyFooter: false,
        muiTablePaperProps: {
            elevation: 0, //change the mui box shadow
            sx: {
                borderRadius: '0',
            },
        },
        // enableRowSelection: true, // чекбоксы для выбора
        enableGlobalFilter: false, // поиск по все таблице в шапке
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,

        enableColumnFilters: false,
        enableColumnActions: false, // настройки колонки
        enableSorting: false, // отключает сортировку по колонкам
        paginationDisplayMode: 'pages',

        muiTableBodyCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
            },
        },
        muiTableProps: {
            sx: { boxShadow: "none" }
        },
        muiTableContainerProps: {
            sx: { maxHeight: '100%', boxShadow: "none" }
        },
        muiTableHeadCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
                fontSize: "1rem"
            },
        },
        muiPaginationProps: {
            rowsPerPageOptions: [10, 30, 50, 100],
            showRowsPerPage: false,
            showFirstButton: false,
            showLastButton: false,
            shape: 'rounded',
            // showRowsPerPage: false, // показать/скрыть колич на странице
            variant: 'outlined',
        },
        localization: {
            and: 'и',
            cancel: 'Отмена',
            search: 'Искать',
            showHideSearch: 'Поиск по всей таблице',
            rowsPerPage: 'Cтрок',
            goToLastPage: 'Предыдущая',
            goToNextPage: 'Следующая',
        },
        renderEmptyRowsFallback: () => (
            <Typography textAlign='center'>Ничего не найдено</Typography>
        ),

    });

    const initDataTable = (anyData: any[]) => {

        const arrDataTable: any = [];
        anyData.forEach((e, i) => {
            arrDataTable.push({
                number: i + 1,
                data: e
            })
        });
        setTableData(arrDataTable);
    }

    useEffect(() => {
        if (data) initDataTable(data.teams);
    }, [data])

    return (
        <>
            <Box>
                <Card >
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant="h5">Структура</Typography>
                        {user.id != data.info.id && <Box>
                            <Button
                                size="large"
                                sx={{ mr: 1 }}
                                color="primary"
                                variant="contained"
                                onClick={() => updPersonInfoId(user.id)}
                            >В начало</Button>
                            <Button
                                size="large"
                                // sx={{ background: "#13DEB9", color: "white" }}
                                variant="contained"
                                color="success"
                                onClick={() => updPersonInfoId(data.info.referer_id)}
                            >Назад</Button>
                        </Box>}
                    </Box>
                    <CardContent>
                        <Grid container spacing={2} justifyContent='center' display='flex'>
                            <Grid item xs={6} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.info.first_name} ({data.info.login})</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Логин пользователя</Typography>
                            </Grid>
                            <Grid item xs={6} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.referer.name} ({data.referer.login})</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Куратор</Typography>
                            </Grid>
                            <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.count} чел.</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Всего людей</Typography>
                            </Grid>
                            <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.info.email}</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>E-mail</Typography>
                            </Grid>
                            <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{dateRegUser}</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Дата регистрации</Typography>
                            </Grid>
                            <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.info.phone}</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Номер телефона</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>


            <Box py={3} sx={{ width: "100%", overflow: "hidden" }}>
                <MaterialReactTable table={table} />

                {/* <TablePartners updPersonInfoId={updPersonInfoId} teams={data.teams} /> */}
            </Box>
        </>
    )
}
